@import "../../styles/_utils.scss";

.page-dashboard {
	.list-widgets {
		display: grid;
		grid-template-columns: repeat(4, 1fr); // Definir según el número de columnas máximo 
		gap: 15px;

		.widget_1x1 {
			grid-column: span 1;
			grid-row: span 1;
		}

		.widget_1x2 {
			grid-column: span 1;
			grid-row: span 2;
		}

		.widget_1x3 {
			grid-column: span 1;
			grid-row: span 3;
		}

		.widget_1x4 {
			grid-column: span 1;
			grid-row: span 4;
		}

		.widget_2x1 {
			grid-column: span 2;
			grid-row: span 1;
		}

		.widget_2x2 {
			grid-column: span 2;
			grid-row: span 2;
		}

		.widget_2x3 {
			grid-column: span 2;
			grid-row: span 3;
		}

		.widget_2x4 {
			grid-column: span 2;
			grid-row: span 4;
		}

		.widget_3x1 {
			grid-column: span 3;
			grid-row: span 1;
		}

		.widget_3x2 {
			grid-column: span 3;
			grid-row: span 2;
		}

		.widget_3x3 {
			grid-column: span 3;
			grid-row: span 3;
		}

		.widget_3x4 {
			grid-column: span 3;
			grid-row: span 4;
		}

		.widget_4x1 {
			grid-column: span 4;
			grid-row: span 1;
		}

		.widget_4x2 {
			grid-column: span 4;
			grid-row: span 2;
		}

		.widget_4x3 {
			grid-column: span 4;
			grid-row: span 3;
		}

		.widget_4x4 {
			grid-column: span 4;
			grid-row: span 4;
		}

	}

	@media (max-width: 1200px) {
		.list-widgets {
			grid-template-columns: repeat(3, 1fr); // Dos columnas cada una con un 50% del ancho.
		}

		.list-widgets>.widget_1x1,
		.list-widgets>.widget_1x2,
		.list-widgets>.widget_1x3,
		.list-widgets>.widget_1x4,
		.list-widgets>.widget_2x1,
		.list-widgets>.widget_2x2,
		.list-widgets>.widget_2x3,
		.list-widgets>.widget_2x4,
		.list-widgets>.widget_3x1,
		.list-widgets>.widget_3x2,
		.list-widgets>.widget_3x3,
		.list-widgets>.widget_3x4,
		.list-widgets>.widget_4x1,
		.list-widgets>.widget_4x2,
		.list-widgets>.widget_4x3,
		.list-widgets>.widget_4x4 {
			grid-column: span 1;
		}
	}

	@media (max-width: 992px) {
		.list-widgets {
			grid-template-columns: repeat(2, 1fr); // Dos columnas cada una con un 50% del ancho.
		}

		.list-widgets>.widget_1x1,
		.list-widgets>.widget_1x2,
		.list-widgets>.widget_1x3,
		.list-widgets>.widget_1x4,
		.list-widgets>.widget_2x1,
		.list-widgets>.widget_2x2,
		.list-widgets>.widget_2x3,
		.list-widgets>.widget_2x4,
		.list-widgets>.widget_3x1,
		.list-widgets>.widget_3x2,
		.list-widgets>.widget_3x3,
		.list-widgets>.widget_3x4,
		.list-widgets>.widget_4x1,
		.list-widgets>.widget_4x2,
		.list-widgets>.widget_4x3,
		.list-widgets>.widget_4x4 {
			grid-column: span 1;
		}
	}

	@media (max-width: 576px) {
		.list-widgets {
			grid-template-columns: 1fr; // Solo una columna.
		}

		.list-widgets>.widget_1x1,
		.list-widgets>.widget_1x2,
		.list-widgets>.widget_1x3,
		.list-widgets>.widget_1x4,
		.list-widgets>.widget_2x1,
		.list-widgets>.widget_2x2,
		.list-widgets>.widget_2x3,
		.list-widgets>.widget_2x4,
		.list-widgets>.widget_3x1,
		.list-widgets>.widget_3x2,
		.list-widgets>.widget_3x3,
		.list-widgets>.widget_3x4,
		.list-widgets>.widget_4x1,
		.list-widgets>.widget_4x2,
		.list-widgets>.widget_4x3,
		.list-widgets>.widget_4x4 {
			grid-column: span 1;
		}
	}

	.card {
		height: 100%;
		min-height: 272px;

		h2 {
			font-size: 24px;
			font-weight: 600;
		}
	}

	.card-content {
		padding: 5px 0;

		&.content-soon,
		.empty {
			@extend .empty-container;
		}

		overflow-x: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-medium $grey-light; // 1 barra 2 fons (firefox)
	}

	// .sublist-widgets > [class*="col-"] {
	// 	margin-bottom: 30px;
	// }

	[class*="col-"] {
		margin-bottom: 30px;
	}

	.col-container {
		margin-bottom: 0;
	}

	.alert {
		padding: 5px 10px;
		font-size: 13px;
	}

	.item-main {
		display: flex;
		flex-direction: column;

		.item-name {
			font-weight: 500;
		}

		.item-info {
			color: $grey-label;
			margin-top: -3px;
		}
	}

	.list-employees {
		li {
			display: flex;
			align-items: center;
			padding-top: 5px;
			padding-bottom: 1px;

			.avatar {
				margin-right: 10px;
			}
		}
	}

	.clockin {
		.comments {
			height: 40px;
		}

		.counter {
			margin-top: 20px;
			margin-bottom: 7px;
		}

		.label-day {
			display: block;
		}

		.label-start {
			margin-top: 25px;
			display: block;
		}

		.alert {
			margin-bottom: 15px;
		}

		.resumen-title {
			font-size: 14px;
			color: $grey-label;
			margin-bottom: 0;
			font-weight: 100;
		}

		.resumen-list {
			margin-bottom: 20px;
			// color: $grey-label;
		}

		.card-footer {
			background-color: transparent;
			padding: 0;
			display: flex;
			align-items: center;
			border: 0;
			white-space: nowrap;

			.btn {
				margin-right: 10px;
			}
		}
	}

	.title,
	.label {
		color: $grey-label;
		font-weight: 400;
		display: block;
	}

	.title {
		font-size: 16px;
	}

	.table {
		margin-bottom: 0;
	}

	// RESUMEN TOTAL HORAS
	.card-resumen {
		.bloque-horas {
			margin-bottom: 15px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.horas-trabajadas {
			font-size: 20px;
			font-weight: 700;
		}

		.horas-teoricas {
			margin-left: 5px;
		}

		.horas-teoricas-total {
			font-weight: 500;
			margin-left: 2px;
		}
	}

	// PRÓXIMOS CUMPLEAÑOS
	.card-birthday {
		.card-content {
			padding: 0;

			.title:not(:first-child) {
				margin-top: 10px;
			}
		}
	}

	// PROXIMAS AUSENCIAS
	.card-upcoming-absences {
		.card-top {
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 10px;

			h2 {
				margin-bottom: 0;
			}
		}

		@media #{$max-md} {
			.btn-group {
				margin-top: 10px
			}
		}


		.card-content {
			padding: 0;
			// position: relative;

			.title {
				margin-bottom: 5px;

				&:not(:first-child) {
					margin-top: 10px;
				}
			}
		}

		.total-days {
			font-weight: 400;
		}

		.item-info {
			font-size: 14px;
		}

		.title-month {
			text-transform: uppercase;
			font-size: 18px;
			margin-top: 20px;
			text-align: right;
			border-bottom: 1px solid $primary-lighter;
			padding-bottom: 10px;
		}

		.table-calendar {
			margin-top: 10px;
			margin-bottom: 30px;

			.today {
				background: $primary;
				color: white;
			}
		}
	}

	// AUSENCIAS PENDIENTES
	.card-pending-absences {
		.card-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.counter {
			margin-top: 20px;
			flex-direction: column;
			align-items: flex-start;
		}

		.counter-item {
			margin-bottom: 7px;
		}

		.text {
			display: block;
			margin: 7px 0;
		}
	}

	// Gráfico horas semanales
	.card-week-chart {
		.card-content {
			overflow-x: initial;
		}

		#week_chart {
			margin-bottom: -30px;
			margin-left: -15px;
		}
	}


	// planificación turnos semanales
	.card-week-plan {
		.heading {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			.periodo {
				font-weight: 500;
				font-size: 85%;
				margin-left: 7px;
			}

			.heading-actions {
				button {
					font-size: 18px;
				}
			}

			h2,
			.heading-actions {
				margin-bottom: 3px;
			}
		}

	}

	.table-week-plan {
		.th-day {
			text-align: center;
			line-height: 1.2em;
			text-transform: capitalize;
			font-size: 15px;
			padding: 7px;
			height: auto;
		}

		.td-day {
			padding: 0;
			height: auto;
			vertical-align: initial;

			ul {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
			}
		}

		.td-day-empty {
			text-align: center;
		}

		.day-assignment {
			border: 2px solid transparent;
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
			// justify-content: center;
			// align-items: center;
		}

		.day-assignment-head {
			width: 100%;
			text-align: center;
			padding: 3px 5px;
			font-weight: 500;
		}

		.day-assignment-content {
			padding: 3px 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.time-intervals {
				border-bottom: 1px dashed $grey-medium;
			}

			.time-interval {
				font-weight: 500;
			}

			.ubication-info {
				display: flex;
				align-items: center;
				color: $grey-icon;
				font-size: 14px;
				line-height: 1.2em;

				.icon {
					margin-right: 2px;
					font-size: 11px;
				}
			}

			&:empty {
				padding: 0;
			}
		}
	}



	// #week_chart{
	// 	max-width: 720px;
	// 	margin: 0 auto;

	// 	.apexcharts-canvas, .apexcharts-svg{
	// 		width: 100% !important;
	// 	}
	// }

	// próximos festivos
	.card-upcoming-holidays {
		.list-days {
			.day {
				padding: 5px;
				display: flex;

				&.active {
					border-top: 1px solid $primary;
					border-bottom: 1px solid $primary;
					margin-bottom: 5px;
					background: $primary-lighter;
					position: relative;

					.item-info {
						color: $primary;
					}

					.hoy {
						position: absolute;
						color: $primary;
						right: 5px;
						top: 6px;
					}
				}
			}

			.day-date {
				font-weight: 900;
				width: 80px;
			}

			.holiday {
				margin-bottom: 3px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	// MIS ÚLTIMOS FICHAJES ( UNUSED )
	.card-latest-signings {
		.row-latest {
			@extend .row-narrow;
			margin-top: 10px;

			.list-item {
				border: 1px solid $border-color;
				padding: 15px;
			}
		}

		.item-date {
			text-transform: capitalize;
			margin-bottom: 20px;
		}

		.simple-row {
			margin-bottom: 10px;
			align-items: flex-start;

			>* {
				width: 50%;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		// signings
		.item-times {
			margin-top: 20px;
		}

		.item-checkins-info {
			margin-bottom: 1px;
		}

		.info-label {
			font-weight: 500;
			white-space: nowrap;
		}
	}

	// working today

	.employee-name {
		display: flex;
		align-items: center;

		.avatar {
			margin-right: 10px;
		}
	}


	// alertas
	.card-time-alerts {
		.heading {
			justify-content: space-between;
			width: 100%;

			h2 {
				margin-bottom: 0;
			}
		}
	}
}


.tooltip {
	// width: 500px;

	.tooltip-inner {
		max-width: 100%;

		.item-main {
			display: flex;
			flex-direction: column;

			.item-info {
				color: $grey-label;
				margin-top: -3px;
			}
		}

		.list-employees {
			li {
				display: flex;
				align-items: center;
				text-align: left;
				padding-bottom: 1px;

				.avatar {
					margin-right: 7px;
				}
			}
		}
	}
}