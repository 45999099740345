.page-rules {}

.table-rules {
	.icon-operacion {
		font-size: 22px;
	}

	.icon-formula {
		font-size: 16px;
		color: $grey-icon;
	}

	&.table-mobile {
		.label-mini {
			display: inline-block;
			margin-right: 3px;
		}

		.btn-editable {
			font-weight: 500;
		}
	}

	.td-icon {
		width: 25px;
		padding: 5px !important;
	}
}


.modal-rule {
	.text-info {
		span {
			color: $primary;
			font-family: monospace;
			font-weight: bold;
		}
	}

	.text-info-pretty {
		margin-top: -15px;
		overflow-wrap: break-word;
	}

	.list-tags {
		margin-bottom: 20px;
	}

	.form-group-time {
		width: 100%;
	}
}