.table-contracts {
	.btn-transparent {
		text-align: left;
	}

	.contract-description {
		display: block;
		color: $grey-label;
	}
}

.table-employees-in-contract {
	.avatar {
		margin-right: 10px;
	}
}

.objetivo-horas {
	padding: 15px;
	background: $grey-light;
	border: 1px solid $border-color;
	margin-bottom: 15px;
}

.group-hours {
	.form-group {
		max-width: 130px;
		margin-bottom: 5px;

		input {
			text-align: right;
		}

		.input-group-text {
			background: $grey-light;
			border-color: $border-color;
		}
	}

	&.objetivo-diario {
		.form-group {
			max-width: 100px;
		}

		.input-group-text {
			background: white;
		}
	}

	.grid-hours {
		display: flex;
		justify-content: space-between;
	}
}

.vacaciones {
	input {
		max-width: 65px;
	}

	/*
	.vacaciones-tipo {
		width: calc(100% - 80px);
	}*/
}



.contract-edit {
	padding-top: 0;
	margin-top: -5px;

	.form-group-flex {
		.form-label {
			max-width: calc(100% - 65px);
		}
	}

	.form-group-flex.form-row-switch {
		min-height: 26px;
	}

	.alert-footer {
		max-width: 570px;
		width: 70%;
		margin-bottom: -54px;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	@media #{$max-sm} {
		.alert-footer {
			margin-bottom: 5px;
			width: 100%;
		}
	}

	.form-control.is-invalid.form-objetivo {
		padding-right: 10px;
		background: white;
	}

	.form-group-time-type {
		.form-control.is-invalid~.invalid-feedback {
			margin-bottom: -5px;
		}
	}

	@media #{$min-sm} {
		.col1 {
			padding-right: 20px !important;
			margin-bottom: 0;
		}

		.col2 {
			padding-left: 20px !important;
		}
	}
}

.contract-horas {
	.subtitle {
		margin-bottom: 20px;
	}

	.contract-tolerancia {
		margin-top: 30px;

		.input-group {
			width: 120px;
		}
	}

	// .group-hours {
	// 	.form-label {
	// 		font-weight: normal;
	// 	}
	// }
}

.contract-ausencias {}

.input-group-hours {
	width: 80px;
}

.input-group-minutes {
	width: 120px;
}

.input-group-select {
	width: 110px;
}