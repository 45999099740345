@import "../../styles/_buttons.scss";

// plan views

.page-plan-views {
	.list-columns {
		margin-top: 5px;
		margin-bottom: 15px;

		li {
			padding-top: 8px;
			padding-bottom: 8px;
			font-size: 12px;
			border-bottom: 1px solid $border-color;

			&:last-child {
				border-bottom: 0;
			}
		}
	}
}



// plan view modal
.plan-view-edit {
	.list-columns {
		background: $primary-lightest;
	}

	.column-wrap {
		overflow-y: auto;
		transition: all 0.2s ease;
		scrollbar-width: thin;
		scrollbar-color: $grey-medium rgba(0, 0, 0, 0.07); // 1 barra 2 fons (firefox)	
		height: 450px;
	}

	.column-item-tipo {
		display: block;
		width: 100%;
		color: $grey-label;
		font-size: 12px;
		margin-top: -2px;
	}

	.column-item-scope {
		display: inline-block;
		width: auto;
		background-color: rgba(255, 255, 255, 0.4);
		height: 34px;
		padding: 3px 25px 3px 3px;
	}
}