/* List groups */

.page-groups {
	.table-groups {
		.td-name {
			min-width: 18%;
		}

		.td-employees {
			min-width: 18%;
		}

		.td-groups {
			max-width: 30%;
			min-width: 150px;
		}

		.td-calendar {
			min-width: 18%;
		}

		&.table-mobile {
			.group-name {
				margin-bottom: 5px;
				font-weight: 500;
			}

			.group-head {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}

			.calendar-container {
				display: flex;
				align-items: center;
				margin-bottom: 5px;
				font-size: 90%;
				color: $grey;

				svg {
					color: $grey-label;
					font-size: 16px;
					margin-right: 5px;
				}
			}
		}
	}
}

/* Single group */

// group info
.page-group {
	.tab-info-content {
		display: flex;
		flex-direction: column;
		min-height: 400px;
		justify-content: space-between;
	}

	.table-groups {
		.avatar {
			margin-right: 10px;
		}
	}
}


.no-editable {
	color: $grey;
	font-size: 13px;
	margin-left: 10px;
	margin-top: 3px;
}

.group-staffing-edit {
	.checkbox {
		input {
			display: none;
		}
	}
}