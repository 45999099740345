// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,700&display=swap);

// Font Variables
$theme-font: "Fira Sans", sans-serif !default;

// Color Variables
$bg-color: #f7f7f8;
$bg-disabled: #f9f9f9;
$border-color: #e6e6e9;
$text-color: #0d0a2c !default;
$text-info: #888888;
$label-color: #9e9dab;

$primary: #5b49fe !default;
$primary-light: #887bfe !default;
$primary-lighter: #cbc6f7 !default;
$primary-lightest: #EBE9FF !default;
$primary-dark: #4a39e3;
$primary-darker: #1b154c;
$grey: #55536b;
$grey-label: #9e9dab;
$grey-icon: #888;
$grey-medium: #aaa;
$grey-light: #f7f7f8;
$grey-disabled: #eee;
$grey-button: #ededf2;
$grey-button-hover: #ccc;

$red-button: #e25e6b;
$red-button-hover: #dc3545;

$scrollbar-color: $grey-medium rgba(0, 0, 0, 0.07); // 1 barra 2 fons (firefox)

$color-green: #0BCC8A;
$color-green-dark: #0a8a0a;
$color-green-light: #daf5da;
$color-green-lighter: #f5faf5;
$color-green-bg: rgba(77, 214, 77, 0.07);
$color-orange: #f29a0c;
$color-red: #dc3545;
$color-red-light: #f5c4c9;
$color-error: #ce1126;
$color-pink: #ff2280;
$color-yellow: #efc54d;
$color-magenta: #dd377a;
$color-blue: #55a2e6;
$color-blue-dark: #102cba;
$color-note: #F9F9C2;

$btn-primary-color: white !default;
$btn-primary-border: $primary !default;
$btn-primary-background: $primary !default;
$btn-primary-hover-color: white !default;
$btn-primary-hover-border: $primary !default;
$btn-primary-hover-background: $primary-dark !default;
$btn-primary-disabled-color: white !default;
$btn-primary-disabled-border: $primary-light !default;
$btn-primary-disabled-background: $primary-light !default;

$btn-primary-outline-color: $primary !default;
$btn-primary-outline-border: $primary !default;
$btn-primary-outline-background: white !default;
$btn-primary-outline-hover-color: white !default;
$btn-primary-outline-hover-border: $primary !default;
$btn-primary-outline-hover-background: $primary-light !default;



// BREAKPOINTS:

$xxs: 320px;
$xs: 480px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1440px;
$xxl: 1900px;

// MAX SIZE:
$max-xxs: "(max-width: 320px)";
$max-xs: "(max-width: 480px)";
$max-sm: "(max-width: 768px)";
$max-md: "(max-width: 992px)";
$max-lg: "(max-width: 1200px)";

// MIN SIZE:
$min-xs: "(min-width: 480px)";
$min-sm: "(min-width: 768px)";
$min-md: "(min-width: 992px)";
$min-lg: "(min-width: 1200px)";
$min-xl: "(min-width: 1440px)";
$min-xxl: "(min-width: 1900px)";

/* BS querys */
$bs-xsmall-only: "(max-width: 767px)";
$bs-small-only: "(min-width: 768px) and (max-width: 992px)";
$bs-medium-only: "(min-width: 993px) and (max-width: 1199px)";
$bs-large-up: "(min-width: 1200px)";
$bs-large-down: "(max-width: 1199px)";

/* media query examples */
@media #{$max-xxs} {}

@media #{$max-xs} {}

@media #{$max-sm} {}

@media #{$max-md} {}

@media #{$max-lg} {}

@media #{$min-xs} {}

@media #{$min-sm} {}

@media #{$min-md} {}

@media #{$min-xl} {}