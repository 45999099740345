.header {
	background: white;
	top: 0px;
	width: 100%;
	box-shadow: 0 4px 8px 0 rgba(13, 10, 44, 0.04);
	position: fixed;
	width: 100%;
	z-index: 100;

	.app.is_trial & {
		top: 34px;
	}
}


.header-container {
	align-items: center;
	display: flex;
	height: 50px;
	justify-content: space-between;
}

.header-avatar {
	margin-right: 10px;
}

.header-dropdown-arrow {
	margin-left: 5px;
}

.header-logo-link {
	display: inline-block;
}

.header-logo {
	max-height: 32px;
}


.header-iso {
	width: 32px;
}

.header-right {
	display: flex;
	align-items: center;

	.link-help {
		display: inline-block;
		margin-right: 17px;
		font-size: 20px;
		color: $grey-label;

		&:hover {
			color: $grey;
		}
	}

	.header-menu {
		margin-right: 30px;

		ul {
			background: white;
			display: none;
			list-style: none;
			margin: 0px 0px 0px -14px;
			padding: 15px 15px 5px 15px;
			position: absolute;
			right: 25px;
			width: 150px;
			text-align: right;
			box-shadow: 0 4px 8px 0 rgba(13, 10, 44, 0.04);
		}

		&:hover ul,
		ul:hover {
			display: block;
		}

		li {
			margin: 10px 0px;

			a,
			.button-link {
				color: $text-color;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.header-menu-profile {
	align-items: center;
	display: flex;
	cursor: pointer;

	img {
		margin-right: 8px;
		width: 40px;
	}

	>span {
		font-weight: 500;
	}

	@media #{$max-sm} {
		.header-username {
			display: none;
		}
	}
}

// @media #{$min-md} {
// 	.btn-menu {
// 		display: none;
// 	}

// 	.header-logo-link {
// 		margin-left: 20px;
// 	}
// }

.header-trial {
	background: $primary-darker;
	color: white;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	z-index: 100;
	position: fixed;

	.btn {
		padding: 2px 10px;
		margin-left: 10px;
	}

	@media #{$max-sm} {
		padding-left: 5px;
		padding-right: 5px;
		font-size: 13px;
		line-height: 1.2em;

		.btn {
			font-size: 14px;
			line-height: 1em;
			padding: 4px 6px;
			white-space: nowrap;
		}
	}
}

@media #{$min-sm} {

	.header,
	.header-iso {
		display: none;
	}
}

@media #{$max-sm} {
	.header-logo {
		display: none;
	}

	.header-menu {
		margin-right: 20px;
	}

	.header .btn-menu {
		padding-right: 15px;
	}

	.header-menu-item {
		display: inline-block;
	}
}