.page-imports {
	.steps {
		margin-top: 30px;
		margin-bottom: 50px;
		padding-left: 0;

		li {
			margin-bottom: 40px;
			counter-increment: step;
			display: flex;
			align-items: center;

			&:before {
				content: counter(step);
				background: $primary;
				color: white;
				border-radius: 50%;
				width: 50px;
				min-width: 50px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 20px;
				font-weight: bold;
				font-size: 24px;
			}

			.link {
				margin-left: 3px;
				margin-right: 3px;
			}

			.form-control-file {
				margin-top: 7px;
			}

			>div {
				width: 100%;
				max-width: 450px;
			}
		}

		.form-row-switch {
			margin-bottom: 0;
			justify-content: flex-start;
			margin-top: 7px;
			background: white;
			padding: 8px 10px;
			border: 1px solid $border-color;
		}
	}

	.SubmitButton {
		margin-top: 10px;
	}

	.import-list {
		margin-top: 30px;

		.import-list-text {
			font-weight: 500;
			margin-bottom: 5px;
		}

		.import-rows {
			>li {
				margin-bottom: 5px;
				border: $border-color 1px solid;
				padding: 6px 10px;
				background: white;
				display: flex;

				.import-count {
					margin-right: 10px;
					font-weight: bold;
					color: $primary;
					min-width: 45px;

					&.import-count-errors {
						color: $color-red;
					}
				}
			}
		}

		&.import-errors {
			.import-list-text {
				color: $color-red;
			}

			.import-rows {
				>li {
					.import-count {
						color: $color-red;
						min-width: 45px;
					}
				}
			}
		}

		/* 		&.import-messages {
			.import-list-text {
				color: $color-green;
			}
		} */
	}
}