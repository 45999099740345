.category-icon {
	font-size: 26px;
	color: $primary;
	margin-bottom: 10px;
}

.category-name {
	font-size: 20px;
}

.list-documents {
	max-width: 900px;
	margin: 40px auto;

	.document-category {
		border: 1px solid $border-color;
		background: white;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding: 30px 20px;
		margin-bottom: 30px;

		.category-count {
			margin: 0;
		}
	}

	.category-link {
		text-decoration: none !important;
		color: $text-color;

		&:hover {
			cursor: pointer;
			color: $primary;

			.document-category {
				background: $primary-lightest;
				border-color: $primary-light;
			}

			.category-icon {
				color: $text-color;
			}
		}
	}
}

.category-header {
	display: flex;
	align-items: center;
	margin-top: 15px;
	margin-bottom: 15px;

	.category-icon {
		font-size: 18px;
		margin-right: 8px;
	}
}

.modal-documentos {
	.intro-text {
		color: $text-info;
		margin-top: -15px;
	}
}