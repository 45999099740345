.staffing-level-edit {
	.need-count {
		position: absolute;
		width: 100%;
		top: 0;
		height: 100%;
		border: none;
		background: none;
	}

	.init-all-wrapper {
		align-items: center;
		margin-top: 20px;

		.form-label {
			margin-right: 20px;
			font-weight: normal;
		}

		.init-all-input {
			width: 70px;
		}
	}

	.table-staffing-needs {
		margin-top: 15px;

		.th-daytype {
			min-width: 45px;
		}

		tbody {
			th {
				padding: 6px 8px;
				min-width: 170px;
			}

			td {
				position: relative;

				&.filled {
					background: $primary-lightest;

					.rc-input-number-input {
						font-weight: bold;
					}
				}
			}

			tr:nth-child(odd) {

				th,
				td {
					background: $grey-light;

					.need-count {
						border-bottom: 1px solid $border-color;
						border-right: 1px solid $border-color;
						width: calc(100% + 2px);
						height: calc(100% + 1px);
					}

					&.filled {
						background: $primary-lighter;
					}
				}
			}
		}

		.need-ubication,
		.need-interval {
			// color: $text-info;
			font-weight: normal;
			font-size: 90%;
			margin-top: -2px;
			display: flex;
			align-items: center;

			.icon {
				margin-right: 5px;
			}
		}

		.need-ubication {
			margin-right: 10px;
		}

		.need-footer {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.th-all,
		.all-input {
			background-color: $grey !important;
			color: white;
			min-width: 40px;
		}

		.all-count-input {
			color: white;

			.rc-input-number-handler-up-inner::after,
			.rc-input-number-handler-down-inner::after {
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}

			.rc-input-number-input {
				color: white;
			}
		}
	}

	@media #{$min-sm} {
		.col-sm-6 {
			&:first-child {
				padding-right: 20px;
			}

			&:nth-child(2) {
				padding-left: 20px;
			}
		}
	}


	.table-wrapper {
		position: relative;
		overflow-x: auto;
		width: 100%;
		//max-width: 700px;
		scrollbar-width: thin;
		scrollbar-color: $grey-medium $grey-light; // 1 barra 2 fons (firefox)
	}

	.form-group-flex.form-row-switch {
		justify-content: start;

		.form-label {
			margin-left: 10px;
		}
	}
}