// mi calendario
.page-calendar {
	td {
		border: 1px solid $border-color;
		width: calc(100%/7);
	}

	.td-container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		overflow: hidden;
		margin-top: -4px;
		margin-bottom: -4px;

		.calendar-date {
			font-weight: normal;
		}

		.shift-list-items {
			height: 100%;
			font-size: 90%;

			.items_2 {
				font-size: 10px;
				line-height: 12px;
			}

			.items_3 {
				font-size: 8px;
			}
		}

		&.default-shift {
			.calendar-date {
				font-weight: bold;
			}
		}

		&.has-shifts {
			.calendar-date {
				min-width: 50%;
				text-align: right;
				padding-right: 3px;
				white-space: nowrap;
			}
		}

		&.holiday {
			.calendar-date {
				font-weight: bold;
			}
		}
	}

	.header-widgets {
		margin-bottom: 0;
	}

	.btn-caption {
		margin-bottom: 15px;
		margin-left: auto;
		display: flex;
	}

	.calendar-caption {
		background: $primary-lightest;
		margin-bottom: 15px;
		padding: 15px;

		.table-hours {
			margin-bottom: 0;

			thead th {
				height: auto;
			}

			tbody td {
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
	}



}

// listado calendarios
.page-calendars {}

.modal-calendar {
	.subtitle {
		margin-bottom: 15px;
	}
}

.row-special-days {
	.form-control.is-invalid {
		padding-right: 10px;
		background: white;

		&.select-control {
			background-color: white;
			background-image: url("../../assets/icons/arrow-down.svg");
			background-repeat: no-repeat;
			background-position: calc(100% - 7px) 50%;
			background-size: auto 12px;
		}
	}

	.form-date {
		width: 145px;

		.is-invalid~svg {
			right: 5px;
		}
	}

	.custom-selector {
		display: block;
		min-width: 150px;
	}
}

.table-specialdays,
.table-weekdays {
	margin-top: 10px;
}

.table-days.table-weekdays {
	margin-bottom: 30px;

	thead {
		th {

			&:nth-child(6),
			&:nth-child(7) {
				color: $color-pink;
			}
		}
	}
}

.table-specialdays {
	.tag-day-type {
		display: inline-block;
		padding: 3px 10px;
		min-width: 50px;
		text-align: center;
		margin-top: -2px;
		margin-bottom: -2px;
	}
}


.box-total {
	margin-bottom: 50px;

	.box-total-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.button-link {
			color: $primary;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	h4 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 0;

		strong {
			font-weight: 900;
			color: $primary;
			margin-left: 3px;
		}
	}


}

.worked-holiday {
	border: 1px solid $color-pink;
}

.list-worked-holidays {
	margin-top: 25px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);

	li {
		padding: 12px 25px;
		border-bottom: 1px solid $border-color;

		.tag-day-type {
			padding: 5px;
			margin-right: 10px;
		}

		&:last-child {
			border-bottom: 0;
		}
	}
}