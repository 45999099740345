.counter {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;

	.counter-item {
		font-size: 55px;
		line-height: 30px;
		margin-right: 5px;
		position: relative;
		font-weight: 400;
		color: $grey-label;
		flex-wrap: nowrap;

		&:last-of-type {
			margin-right: 0
		}
	}

	.counter-item span {
		//color: #444;
		font-size: 30px;
		margin-left: 1px;
		font-weight: 400;
	}



	@media #{$max-sm} {
		.counter-seconds {
			display: none;
		}
	}
}