.page-time-registrations {
	.main-content {
		margin-bottom: 70px;
	}

	.heading {
		.title {
			display: flex;
			align-items: center;

			.button-icon {
				margin-right: 3px;
			}
		}

		.heading-right {
			display: flex;

			.btn {
				margin-right: 20px;
			}
		}

		@media #{$max-sm} {
			flex-wrap: nowrap;

			.title {
				font-size: 22px;
			}

			.heading-right {
				display: block;


				.btn-aprobar {
					margin-right: 0;
					margin-bottom: 10px;
					padding: 4px 10px;
					font-size: 14px;
				}

				h3 {
					font-size: 16px;
					justify-content: flex-end;
					white-space: nowrap;
				}

			}

			.summary {
				white-space: nowrap;
			}
		}

		@media #{$max-xs} {
			margin-bottom: 5px;
			display: block;

			.title {
				font-size: 18px;
				line-height: 1.3em;

				.button-icon {
					font-size: 24px;
					margin-right: 5px;
				}
			}

			.btn-aprobar {
				margin-top: 7px;
			}

			.heading-right {
				display: block;
				text-align: left;

				.employee-data {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 10px;
				}
			}

			.summary {
				margin-top: 0;
				margin-bottom: 0;

				.summary-text {
					display: none;
				}
			}
		}
	}
}

// común en time registrations y supervisión de tiempos
.table-time-registrations,
.table-times {

	// not main row
	.repetida {
		.btn-trash {
			display: block;
		}

		.tag-dia-container,
		.tag-name-container,
		.switch,
		.btn-new {
			display: none;
		}
	}

	.col-total {
		color: $primary;
		font-weight: 500;
	}

}

.table-time-registrations {

	.td-actions {
		text-align: center;
	}

	.tag-dia-setmana {
		display: inline-block;
		width: 17px;
		color: $primary;
		font-size: 85%;
	}

	.day-type {
		cursor: pointer;
	}

	.shift-list-items {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.shift-item {
		padding: 2px;
		text-align: center;
		margin-right: 5px;
		min-width: 30px;
		border: 2px solid transparent;

		&:hover {
			background: rgba(91, 73, 254, 0.2);
			cursor: pointer;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	.switch {
		margin-left: auto;
		margin-right: auto;
	}

	&.table-mobile {
		.tr-head {
			td {
				padding-bottom: 0;
				font-weight: bold;
			}

			.total {
				color: $primary;
			}

			.balance {
				font-weight: normal;
			}

			.td-flex {
				align-items: center;
				justify-content: space-between;
			}
		}

		.repetida {
			margin-top: -10px;

			td {
				border-top: 0;
				padding-top: 0;
			}
		}

		.main {
			td {
				border-top: 0;
				padding-top: 10px;
			}
		}

		.time-container:first-of-type {
			margin-left: -2px;
		}

		.td-actions {
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	.today {
		border-left: 1px solid $primary;
		border-right: 1px solid $primary;

		td {
			&:first-child {
				border-left: 1px solid $primary;
			}

			&:last-child {
				border-right: 1px solid $primary;
			}
		}

		&.main:not(.tr-body) td,
		&.tr-head td {
			border-top: 1px solid $primary;
		}

		&:last-child td {
			border-bottom: 1px solid $primary;
		}

		td {
			background: $primary-lightest !important;
		}
	}

	.option-remove-rest {
		display: none;
	}

	.rest-day {
		td {
			background-color: $color-green-bg !important;
		}

		.col-descanso {
			color: $color-green-dark;
		}

		.option-remove-rest {
			display: block;
		}

		.option-add-time,
		.option-delete,
		.option-observations {
			display: none;
		}
	}



	// approved day
	.disabled {
		.btn-new {
			display: none;
		}

		.btn-trash {
			display: none;
		}
	}

	// empty row without time
	.filled {
		.option-rest {
			display: none;
		}
	}

	.empty {
		//opacity:0.6;

		.btn-new,
		.switch,
		.bolita,
		.option-add-time,
		.option-delete,
		.option-observations {
			display: none;
		}
	}

	.btn-new {
		min-width: 25px;
	}
}

.td-flex {
	display: flex;
	align-items: center;

	>* {
		margin-right: 7px;

		&:last-child {
			margin-right: 0;
		}
	}
}



.tag-dia-setmana {
	margin-right: 5px;
}

.icon-comment {
	color: $grey-label;
	cursor: pointer;
}

// Times.js
.page-times {
	.heading {
		margin-bottom: 0;
	}

	.subheading {
		margin-bottom: 15px;

		.summary {
			display: flex;
			align-items: center;

			.summary-icon {
				margin-right: 6px;
				font-size: 20px;
				margin-top: 4px;
			}
		}
	}
}

.sortable-item {
	cursor: pointer;
	white-space: nowrap;

	&:hover {
		color: $primary;
	}

	.icon-sort {
		height: 12px;
		margin-bottom: 2px;

		&.icon-sort-up {
			margin-bottom: 0;
			margin-top: 3px;
		}

		&.icon-sort-down {
			margin-bottom: 5px;
		}
	}
}


.tooltip-balance {
	text-align: left !important;
	max-width: 250px;

	.tooltip-title {
		margin-top: 10px
	}

	p {
		margin-bottom: 2px;
	}
}

.switch-container-overlay {
	width: 45px;
	margin-left: auto;

	.switch {
		float: none;
	}
}