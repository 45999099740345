// css for profile, company and subscription

// profile
.page-profile {
	.card {
		max-width: 400px;
	}

	.avatar {
		display: block;
		margin-bottom: 20px;
	}

	.button-password {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

// subscription

.page-subscription {
	.title {
		text-align: center;
	}

	.row-switch-double {
		// transform: translateX(45px);
		margin-bottom: 25px;

		.label-switch {
			&.active {
				font-weight: 500;
			}
		}
	}

	.container-precios {
		max-width: 800px;
		margin-top: 15px;
	}

	.precios-titulo {
		font-size: 20px;
	}

	.precio-sin-iva {
		text-align: center;
		color: #222;
		margin-top: -17px;
		font-size: 14px;
	}

	.precios-item {
		background: #fff;
		border-radius: 10px;
		text-align: left;
		padding: 30px;
		margin-bottom: 30px;

		.btn {
			width: 100%;
		}
	}

	.precios-precio {
		margin-top: 20px;

		.precios-valor {
			color: #071026;
			font-size: 34px;
			font-weight: 700;
		}

		.precios-periodo {
			color: #071026;
			font-weight: 500;
			margin-top: 7px;
		}
	}

	.active-users,
	.contract-users {
		text-align: center;
		margin-bottom: 20px;
		margin-top: -20px;
		color: $color-green-dark;
	}

	.contract-users {
		color: $text-color;
	}

	.lista-features {
		margin-left: 0;
		padding-left: 0;
		margin-top: 20px;
		margin-bottom: 40px;
		list-style: none;

		li {
			padding: 5px 0 5px 27px;
			background-image: url("../../assets/icons/icon-check.svg");
			background-position: 0 50%;
			background-size: 16px 16px;
			background-repeat: no-repeat;
		}

		@media (max-width: 760px) {
			margin-bottom: 0;
			margin-left: auto;
			margin-right: auto;
			display: inline-block;

			&:last-child {
				margin-top: 0;
			}
		}
	}
}

.modal-subscription {
	.intro {
		margin-top: -10px;
		margin-bottom: 25px;
	}

	.step-content {
		min-height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&.step-content-free {
			align-items: center;
		}

		h3 {
			color: $primary;
			font-weight: bold;
			font-size: 22px;
		}
	}

	.row-empleados {
		margin-top: 30px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		background: $grey-light;
		padding: 15px;

		.form-group {
			margin-bottom: 5px;
		}
	}

	.row-switch-double {
		.label-switch {
			&.active {
				font-weight: 500;
			}
		}
	}

	.total {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		.total-label {
			font-weight: 500;
		}

		.total-num {
			font-size: 40px;
			font-weight: 700;
			line-height: 1em;

			&:after {
				content: "/mes";
				font-weight: normal;
				font-size: 11px;
			}
		}
	}
}

// company
// .page-company {
// }