// avatar
.avatar {
	border-radius: 50%;
	overflow: hidden;
	background: $bg-color;

	&.avatar-xxs {
		width: 25px;
		height: 25px;
	}

	&.avatar-xs {
		width: 30px;
		height: 30px;
	}

	&.avatar-sm {
		width: 40px;
		height: 40px;
	}

	&.avatar-md {
		width: 70px;
		height: 70px;
	}

	&.avatar-lg {
		width: 120px;
		height: 120px;
	}

	&.avatar-editable {
		cursor: pointer;
	}
}

.avatar-edit {
	height: 340px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;

	canvas {
		margin-top: 5px;
	}
}

.avatar-zoom {
	margin-top: 10px;
	margin-bottom: 5px;

	label {
		margin-right: 7px;
		font-weight: 500;
	}
}


.avatar-collection {
	margin-right: 10px;

	li {
		display: inline-block;
		margin-left: -10px;

		&:first-of-type {
			margin-left: 0;
		}
	}

	.avatar {
		border: 2px solid white;
	}
}

.avatar-container {
	display: flex;
	align-items: center;

	.avatar-collection {
		margin-right: 10px;
		white-space: nowrap;
	}
}