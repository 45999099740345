.react-contextmenu {
	z-index: 2 !important;
	position: relative;
	background: white;
	border: 1px solid $border-color;
	border-radius: 4px;
	background-clip: padding-box;
	min-width: 10rem;
	transform: translate3d(-10px, 5px, 0px);

	.react-contextmenu-item {
		padding: 7px 25px;
		cursor: pointer;

		&:hover {
			background: $grey-light;
		}
	}
}


.shift-pattern-edit {
	min-height: 660px;

	.form-group-flex {
		align-items: center;
	}
}

.shift-list-filter,
.pattern-filter {
	min-width: 130px;
	max-width: 140px;
	margin-right: 10px;

	.pattern-box {
		background: $grey-light;
		padding: 10px;
		border: 1px solid $border-color;
	}

	.pattern-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.pattern-footer {
		color: $label-color;
		text-align: center;
		font-size: 13px;
		margin-top: 10px;

		svg {
			display: block;
			margin: 0 auto 3px auto;
		}
	}

	.pattern-title {
		font-size: 14px;
		font-weight: 500;
		margin: 0;
	}

	.heading-filters {
		margin-bottom: 10px;
	}

	.form-search {
		margin-bottom: 5px;
	}

	.shift-list,
	.pattern-list {
		max-height: 320px;
		min-height: 230px;
		overflow-y: auto;
		transition: all 0.2s ease;
		scrollbar-width: thin;
		scrollbar-color: $grey-medium rgba(0, 0, 0, 0.07); // 1 barra 2 fons (firefox)	

		.shift-item {
			color: black;
			margin-bottom: 5px;
			border-color: $border-color;
			min-height: 35px;

			.shift-name {
				font-weight: 700;
			}

			&.active {
				border: 2px solid rgba(0, 0, 0, 0.3) !important;
			}

			&:hover {
				opacity: 0.85;
			}

			.shift-times {
				font-size: 12px;
				display: block;

				.clock-icon {
					display: none;
				}

				.separador {
					display: inline-block;
					width: 8px;
				}
			}
		}
	}
}

.bloque-plan {

	.shift-list-filter,
	.pattern-filter {

		.shift-list,
		.pattern-list {
			max-height: calc(100vh - 450px);
		}
	}
}


.shift-cell,
.pattern-cell,
.need-cell {
	.btn-check-label {
		position: absolute;
		height: 100%;
		bottom: 0;
	}

	&.btn-checkbox {
		border-color: $border-color;
	}

	&.active {

		//background: $primary-lightest;
		.react-contextmenu-wrapper {
			border: 1px solid $primary;
		}

		.shift-item {
			background: white;
		}
	}

	&.error {
		.react-contextmenu-wrapper {
			border: 2px solid $color-red;
		}
	}

	&:hover {
		opacity: 0.85;

		.shift-item {
			background: white;
		}
	}
}

.shift-list-items {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: space-between;

	.shift-item {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}




.pattern-container {
	display: flex;
	justify-content: space-between;

	.pattern-plan {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% - 160px);
		margin-right: -4px;
		//height: 0;
		height: 100%;

		.pattern-cell {
			border: 1px solid $border-color;
			width: calc(14.2% - 2px);
			flex-grow: 0;
			margin-right: 2px;
			margin-bottom: 2px;
			position: relative;
			height: 80px;

			.cell-number {
				position: absolute;
				z-index: 1;
				top: 2px;
				left: 5px;
				font-weight: 700;
			}

			.react-contextmenu-wrapper {
				//padding: 2px 5px;
				display: flex;
				width: 100%;
				height: 78px;
			}
		}
	}

	.ubication-plan {
		.shift-item {
			border: 3px solid transparent;
			height: 100%;
			// max-height: 55px;

			.shift-name {
				display: block;
				text-align: right;
				// font-weight: 500;
				font-size: 13px;
				color: $grey;
				padding-right: 3px;
			}
		}

		.pattern-cell.ubication-cell {
			height: 113px;
			text-align: center;


			// shift list inside ubication cell
			.shifts-with-ubications {
				height: 100%;
				display: flex;
				flex-direction: column;

				.react-contextmenu-wrapper {
					height: 100%;
					flex-direction: column;
				}
			}

			// ubication list inside shift
			.shift-list-items-ubications {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				height: 100%;
			}

			.ubication-item {
				text-align: center;
				padding: 0 2px;
				width: 100%;
				height: 100%;
				font-size: 85%;
				//line-height: 1em;
				display: flex;
				align-items: center;
				justify-content: center;

				.react-contextmenu-wrapper {
					max-height: 16px;
					overflow: hidden;
				}
			}
		}
	}

}