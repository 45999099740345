.switch {
	width: 45px;
	height: 25px;
	position: relative;

	.aright & {
		float: right;
	}

	&.disabled {
		opacity: 0.5;
	}
}

.react-switch-label {
	cursor: pointer;
	width: 45px;
	height: 25px;
	background: gray;
	border-radius: 50px;
	position: absolute;
	transition: background-color 0.2s;
	margin-bottom: 0;
	z-index: 1;
	left: 0;
	top: 0;

	.react-switch-button {
		content: "";
		position: absolute;
		top: 2px;
		left: 2px;
		width: 20px;
		height: 20px;
		border-radius: 20px;
		transition: 0.2s;
		background: #fff;
		box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
	}

	&:active .react-switch-button {
		width: 25px;
	}
}

.react-switch-checkbox {
	height: 0;
	width: 0;
	visibility: hidden;
	z-index: 0;
	left: 0;
	top: 0;

	&:checked+.react-switch-label .react-switch-button {
		left: calc(100% - 2px);
		transform: translateX(-100%);
	}
}

.row-switch {
	display: flex;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 5px;
	height: 50px;

	&.row-switch-double {
		justify-content: center;

		.switch {
			margin-right: 7px;
			margin-left: 7px;
		}

		.react-switch-label {
			background: gray !important;
		}
	}
}


.switch-container-overlay {
	width: 45px;
}