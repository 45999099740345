// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,700&display=swap);

// Font Variables
$theme-font: "Open Sans", sans-serif !important;

$negro: #020203;
$salmon: #ffb380;
$salmon2: #ffe0cc;
$azul: #3528ff;
$azul2: #f0f5ff;

$primary: $azul;
$primary-light: lighten($azul, 30%);
$primary-lighter: lighten($azul, 50%);
$primary-lightest: $azul2;
$primary-dark: #666; // no es muy dark, pero es el color que se usa en el hover
$accent: $salmon;

$btn-primary-background: $negro;
$btn-primary-border: $negro;
$btn-primary-hover-border: $primary-dark;
$btn-primary-hover-background: $primary-dark;
$btn-primary-disabled-color: $primary-dark;
$btn-primary-disabled-border: #999;
$btn-primary-disabled-background: #e3e3e3;

$btn-primary-outline-color: $negro;
$btn-primary-outline-border: $negro;
$btn-primary-outline-background: white;
$btn-primary-outline-hover-color: $negro;
$btn-primary-outline-hover-border: $negro;
$btn-primary-outline-hover-background: $salmon2;


@import "../../_main.scss";

body {
	--primary: #000;
	--primary-light: #666;
}

/* General */

.title {
	font-size: 24px;
}

.modal-title {
	font-size: 24px;
	font-weight: bold;
}

/* Layout */

.sidebar {

	a.active,
	a:link.active,
	a:visited.active {
		color: $azul;
		background-color: $azul2;
	}

	.sidebar-logo,
	.sidebar-iso {
		width: auto;
		height: 50px !important;
		margin-left: -10px;
	}

	.sidebar-logo-link {
		padding: 0;
	}
}

.header {

	.header-logo,
	.header-iso {
		width: 50px;
		height: auto;
	}
}


/* Dashboard */

.page-dashboard {
	.card {
		h2 {
			font-size: 21px;
			font-weight: bold;
		}

		.card-content {
			.title {
				font-size: 18px;
			}
		}
	}

	.counter-item {
		font-weight: 600;
	}
}

/* Reglas */

.modal-rule {
	.text-info {
		span {
			color: $accent;
		}
	}
}


/* Restricted / end trial */

.page-end-trial {

	.main-logo {
		margin-bottom: 0;
	}

	.btn {
		margin-top: 25px;
	}

}